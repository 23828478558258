import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../services/notification/notification.service';
import {AuthService} from '../services/auth/auth.service';
import {AppConfig} from '../app.config';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    notifier: NotificationService | null = null;
    authService: AuthService | null = null;

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector) {

    }


    handleError(error: Error | HttpErrorResponse | TypeError) {
        if (error == null) {
            return;
        }
        if (this.notifier == null) {
            try {
                this.notifier = this.injector.get(NotificationService);
            } catch (e) {
            }
        }
        if (this.authService == null) {
            try {
                this.authService = this.injector.get(AuthService);
            } catch (e) {
            }
        }

        console.error('Error occurred!');
        console.error(error);

        if (this.notifier != null) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 400: {
                        this.notifier.error('REQUEST_BAD');
                        break;
                    }
                    case 401: {
                        if (!document.location.href.includes('/login') && !document.location.href.includes('/approve_code')
                            && !document.location.href.includes(AppConfig.settings.loginUrl)) {
                            this.authService.updateAccessHeaders().subscribe();
                        } else {
                            this.notifier.error('REQUEST_UNAUTHORIZED');
                        }
                        break;
                    }
                    case 402: {
                        this.notifier.error('REQUEST_PAYMENT_REQUIRED');
                        break;
                    }
                    case 403: {
                        this.notifier.error('REQUEST_FORBIDDEN');
                        break;
                    }
                    case 404: {
                        this.notifier.error('REQUEST_NOT_FOUND');
                        break;
                    }
                    case 405: {
                        this.notifier.error('REQUEST_METHOD_NOT_ALLOWED');
                        break;
                    }
                    case 406: {
                        this.notifier.error('REQUEST_NOT_ACCEPTABLE');
                        break;
                    }
                    case 407: {
                        this.notifier.error('REQUEST_PROXY_AUTHENTICATION_REQUIRED');
                        break;
                    }
                    case 408: {
                        this.notifier.error('REQUEST_TIMEOUT', {}, 10000);
                        break;
                    }
                    case 409: {
                        this.notifier.error('REQUEST_CONFLICT');
                        break;
                    }
                    case 410: {
                        this.notifier.error('REQUEST_GONE');
                        break;
                    }
                    case 411: {
                        this.notifier.error('REQUEST_LENGTH_REQUIRED');
                        break;
                    }
                    case 412: {
                        this.notifier.error('REQUEST_PRECONDITION_FAILED');
                        break;
                    }
                    case 413: {
                        this.notifier.error('REQUEST_ENTITY_TOO_LARGE');
                        break;
                    }
                    case 414: {
                        this.notifier.error('REQUEST_URI_TOO_LONG');
                        break;
                    }
                    case 415: {
                        this.notifier.error('REQUEST_UNSUPPORTED_MEDIA_TYPE');
                        break;
                    }
                    case 416: {
                        this.notifier.error('REQUEST_REQUESTED_RANGE_NOT_SATISFIABLE');
                        break;
                    }
                    case 417: {
                        this.notifier.error('REQUEST_EXPECTATION_FAILED');
                        break;
                    }
                    case 500: {
                        this.notifier.error('REQUEST_INTERNAL_SERVER_ERROR');
                        break;
                    }
                    case 501: {
                        this.notifier.error('REQUEST_NOT_IMPLEMENTED');
                        break;
                    }
                    case 502: {
                        this.notifier.error('REQUEST_BAD_GATEWAY');
                        break;
                    }
                    case 503: {
                        this.notifier.error('REQUEST_SERVICE_UNAVAILABLE');
                        break;
                    }
                    case 504: {
                        this.notifier.error('REQUEST_GATEWAY_TIMEOUT');
                        break;
                    }
                    case 505: {
                        this.notifier.error('REQUEST_HTTP_VERSION_NOT_SUPPORTED');
                        break;
                    }

                    default: {
                        this.notifier.error('REQUEST_UNKNOWN_ERROR', {}, 10000);
                    }
                }
                return;
            }
            if (error instanceof TypeError) {
                this.notifier.error('INTERNAL_APP_ERROR');
                return;
            }
            if (error instanceof Error) {
                if (error.message.includes('ChunkLoadError')) {
                    this.notifier.error('CHUNK_LOAD_ERROR');
                } else {
                    this.notifier.error(error.message);
                }
            } else {
                this.notifier.error(error);
            }
        }
    }
}
