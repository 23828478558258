import {Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import {ConfigService} from '../@vex/services/config.service';
import {Settings} from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {NavigationService} from '../@vex/services/navigation.service';
import icDomain from '@iconify/icons-ic/twotone-domain';
import icRole from '@iconify/icons-ic/twotone-accessibility';
import icScope from '@iconify/icons-ic/twotone-adjust';
import icResource from '@iconify/icons-ic/twotone-account-tree';
import icAccount from '@iconify/icons-ic/twotone-contact-page';
import icUsers from '@iconify/icons-ic/twotone-person';
import icService from '@iconify/icons-ic/twotone-build';

import {LayoutService} from '../@vex/services/layout.service';
import {ActivatedRoute} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SplashScreenService} from '../@vex/services/splash-screen.service';
import {Style, StyleService} from '../@vex/services/style.service';
import {ConfigName} from '../@vex/interfaces/config-name.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth/auth.service';

@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'vex';

    constructor(private configService: ConfigService,
                private styleService: StyleService,
                private renderer: Renderer2,
                private platform: Platform,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) private localeId: string,
                private layoutService: LayoutService,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private authSerivce: AuthService,
                private splashScreenService: SplashScreenService,
                private translate: TranslateService) {
        Settings.defaultLocale = this.localeId;
        translate.langs = ['en', 'ru'];
        if (localStorage.getItem('language') != null) {
            translate.use(localStorage.getItem('language'));
        } else if (translate.getBrowserLang().indexOf('ru') > -1) {
            translate.use('ru');
        } else {
            translate.use('en');
        }
        this.document.documentElement.style.setProperty('--color-primary', '0, 130, 220');
        this.document.documentElement.style.setProperty('--color-primary-contrast', '255, 255, 255');

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        /**
         * Customize the template to your needs with the ConfigService
         * Example:
         *  this.configService.updateConfig({
         *    sidenav: {
         *      title: 'Custom App',
         *      imageUrl: '//placehold.it/100x100',
         *      showCollapsePin: false
         *    },
         *    showConfigButton: false,
         *    footer: {
         *      visible: false
         *    }
         *  });
         */

        /**
         * Config Related Subscriptions
         * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
         * Example: example.com/?layout=apollo&style=default
         */
        this.route.queryParamMap.pipe(
            map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
        ).subscribe(isRtl => {
            this.document.body.dir = isRtl ? 'rtl' : 'ltr';
            this.configService.updateConfig({
                rtl: isRtl
            });
        });

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('layout'))
        ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('style'))
        ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

        this.authSerivce.currentUser().subscribe((user) => {
            this.navigationService.items = [{
                type: 'link',
                label: 'USER_SETTINGS',
                route: '/user',
                icon: icAccount
            }];
            if (this.authSerivce.isAdmin()) {
            Array.prototype.push.apply(this.navigationService.items, [{
                    type: 'link',
                    label: 'USERS_LIST',
                    route: '/users',
                    icon: icUsers
                }, {
                    type: 'link',
                    label: 'CLIENTS_LIST',
                    route: '/clients',
                    icon: icDomain
                }, {
                    type: 'link',
                    label: 'ROLES_LIST',
                    route: '/roles',
                    icon: icRole
                }, {
                    type: 'link',
                    label: 'SCOPES_LIST',
                    route: '/scopes',
                    icon: icScope
                }, {
                    type: 'link',
                    label: 'RESOURCES_LIST',
                    route: '/resources',
                    icon: icResource
                }]);
            }
            if (this.authSerivce.serviceToolsEnabled()) {
                this.navigationService.items.push({
                    type: 'link',
                    label: 'SERVICE_PAGE',
                    route: '/service',
                    icon: icService
                });
            }
        });
    }

}
