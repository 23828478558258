<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 *ngIf="isUpdateMode()"
            class="headline m-0"
            fxFlex="auto">{{ form.get('login').value }}</h2>
        <h2 *ngIf="isCreateMode()"
            class="headline m-0"
            fxFlex="auto">{{'NEW_USER'|translate}}</h2>

        <button *ngIf="mode == 'update'" [matMenuTriggerFor]="settingsMenu" class="text-secondary"
                mat-icon-button type="button">
            <mat-icon [icIcon]="icMoreVert"></mat-icon>
        </button>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <!-- Login -->
    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="mt-6 flex-auto flex-same">
                <mat-label>{{'LOGIN'|translate}}</mat-label>
                <input [maxlength]="36" required pattern="[\w.-]+" minlength="3" cdkFocusInitial
                       formControlName="login" matInput>
                <mat-error *ngIf="isCreateMode() && login.invalid">
                    <span *ngIf="login.errors.required">
                        {{'LOGIN_IS_REQUIRED'|translate}}
                        <hr/>
                    </span>
                    <span *ngIf="login.errors.minlength">
                        {{'LOGIN_MIN_LENGTH_3'|translate}}
                        <hr/>
                    </span>
                    <span *ngIf="login.errors.pattern">
                        {{'LOGING_WRONG_PATTERN'|translate}}
                        <hr/>
                    </span>
                </mat-error>
                <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <mat-form-field class="sm:mt-6 sm:ml-6  flex-auto password-field flex-same">
                <button *ngIf="!visiblePassword" type="button" matSuffix (click)="showPassword()">
                    <mat-icon [icIcon]="icVisibilityOff" class="mr-3" matPrefix></mat-icon>
                </button>
                <button *ngIf="visiblePassword" type="button" matSuffix (click)="hidePassword()">
                    <mat-icon [icIcon]="icVisibility" class="mr-3" matPrefix></mat-icon>
                </button>

                <mat-label>{{'PASSWORD'|translate}}</mat-label>
                <input (focus)="passwordEdit()" autocomplete="new-password" cdkFocusInitial matInput formControlName="password"
                       [type]="passwordInputType">

                <button class="mr-3" *ngIf="!visiblePassword" type="button" matPrefix
                        (click)="showPassword()">
                    <mat-icon [icIcon]="icLock" matPrefix></mat-icon>
                </button>
                <button class="mr-3" *ngIf="visiblePassword" type="button" matPrefix
                        (click)="hidePassword()">
                    <mat-icon [icIcon]="icLockOpen" matPrefix></mat-icon>
                </button>

            </mat-form-field>
        </div>

        <!-- Full name -->
        <mat-form-field class="flex-auto">
            <mat-label>{{'FULL_NAME'|translate}}</mat-label>
            <input formControlName="fullName" matInput>
            <mat-icon [icIcon]="icContact" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field class="flex-auto">
            <mat-label>{{'EMAIL'|translate}}</mat-label>
            <input pattern="[\w.-]+[@][\w-]+[.][\w.-]+" formControlName="email" matInput>
            <mat-error *ngIf="isCreateMode() && email.invalid">

                <span *ngIf="email.errors.pattern">
                        {{'EMAIL_WRONG_PATTERN'|translate}}
                    <hr/>
                </span>
            </mat-error>
            <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <!-- BSauth -->
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto flex-same">
                <mat-label>{{'BSAUTH_KEY'|translate}}</mat-label>
                <input formControlName="bsauthKey" matInput>

                <mat-icon [icIcon]="icKey" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <div class="sm:mt-6 sm:ml-6  flex-auto flex-same slide">
                <mat-slide-toggle #slide color="primary" formControlName="enabled">
                    <span>{{(slide.checked ? 'USER_ENABLED' : 'USER_DISABLED')|translate}}</span>
                </mat-slide-toggle>
            </div>
        </div>

        <!-- Role addition -->
        <tags-add-component [tagName]="'role'" [icon]="icRole" [allTags]="allRoles"
                            [chosenTags]="chosenRoles"></tags-add-component>
    </mat-dialog-content>

    <!-- Buttons -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{'CANCEL'|translate}}</button>
        <button *ngIf="isCreateMode()" [disabled]="!formValid()" color="primary" mat-button
                type="submit">{{'CREATE_USER'|translate}}</button>
        <button *ngIf="isUpdateMode()" [disabled]="!formValid()" color="primary" mat-button
                type="submit">{{'UPDATE_USER'|translate}}</button>
    </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="delete()" mat-menu-item>
        <mat-icon [icIcon]="icDelete"></mat-icon>
        <span>{{'DELETE'|translate}}</span>
    </button>
</mat-menu>
<div *ngIf="loading|async" class="loadingShield"></div>
