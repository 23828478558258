import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {UserModel} from '../../model/user.model';
import {AppConfig} from '../../app.config';
import {catchError, filter, map, skip} from 'rxjs/operators';
import {AuthModel} from '../../model/auth.model';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient, private httpBackend: HttpBackend) {
        if (!this.isLogined()) {
            this.logout();
        }
    }
    authData: AuthModel = localStorage.getItem('auth') != null ? JSON.parse(localStorage.getItem('auth'))
        : {refreshTokenExpiration: 0, accessTokenExpiration: 0} as AuthModel;
    tokenUpdating = null;

    headers$ = new BehaviorSubject<any>(this.authData.accessToken ? {
        authorization: 'BEARER ' + this.authData.accessToken
    } : {});

    userSubject = new BehaviorSubject<UserModel | null>(
        localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : {});
    userUpdated = false;

    public isAdmin(): boolean{
        return true;
        return this.userSubject.value.roleCodes.includes('ADMIN');
    }
    public canAddRoles(user: UserModel): boolean{
        return this.isAdmin();
    }
    public serviceToolsEnabled(): boolean{
        return false;
    }


    currentUser(): BehaviorSubject<UserModel> {
        if (!this.userUpdated) {
            this.userUpdated = true;
            Promise.resolve().then(() => {
                if (!this.isLogined()) {
                    this.updateAccessHeaders();
                } else {
                    this._updateCurrentUser();
                }
            });
        }
        return this.userSubject;
    }

    headers(): BehaviorSubject<any> {
        if (AppConfig.settings != null) {
            return this.headers$;
        } else {
            return new BehaviorSubject<any>({});
        }
    }

    _updateCurrentUser() {
        this.http.get(AppConfig.settings.apiUrl + '/users/current').subscribe(
            (x: any) => {
                localStorage.setItem('user', JSON.stringify(x));
                this.userSubject.next(new UserModel(x));
            },
            () => {
                this.userUpdated = false;
            }
        );
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        this.login();
    }
    login() {
        if (!document.location.href.includes('/approve_code')) {
            document.location.href = AppConfig.settings.loginUrl
                + '?client_id=' + AppConfig.settings.clientId
                + '&response_type=code'
                + '&redirect_uri=' + AppConfig.settings.redirectUrl;
        }
    }
    isLogined() {
        return localStorage.getItem('auth') !== null;
    }

    public getHeaders(): Observable<any> {
        if (this.isLogined() && this.isAccessTokenExpired()) {
            return this.updateAccessHeaders();
        } else {
            return this.headers$;
        }
    }

    approveCode(code: string): Observable<boolean> {
        const request = new HttpRequest('POST', AppConfig.settings.localAuthUrl + '/approve-code',
            {code, clientId: AppConfig.settings.clientId, redirectUri: AppConfig.settings.redirectUrl});

        return this.httpBackend.handle(request).pipe(
            map(
                (ev: HttpEvent<any>) => {
                    if (ev.type === HttpEventType.Response && ev.status === 200) {
                        this.authData.refreshTokenExpiration = -1;
                        this.authData.accessTokenExpiration = Date.now() + (Number(ev.body.expires_in) * 1000);
                        this.authData.accessToken = ev.body.access_token;
                        this.authData.refreshToken = ev.body.refresh_token;
                        this.headers$.next({
                            authorization: 'BEARER ' + this.authData.accessToken
                        });
                        localStorage.setItem('auth', JSON.stringify(this.authData));
                        this._updateCurrentUser();
                        return true;
                    }
                    if (ev.type === HttpEventType.Response && ev.status !== 200) {
                        return false;
                    }
                }
            ));
    }

    // Returns new headers
    public updateAccessHeaders(): Observable<any> {
        if (!document.location.href.includes('/approve_code')) {
            if (!this.isLogined() || this.isRefreshTokenExpired()) {
                this.logout();
            } else {
                if (this.tokenUpdating == null) {
                    const request = new HttpRequest('POST', AppConfig.settings.localAuthUrl + '/refresh-token',
                        {refreshToken: this.authData.refreshToken, clientId: AppConfig.settings.clientId});
                    this.tokenUpdating = this.httpBackend.handle(request).pipe(
                        catchError(err => {
                            if (err instanceof HttpErrorResponse && err.status !== null && err.status !== 408) {
                                if (err.status === 401){
                                    this.logout();
                                }else {
                                    this.login();
                                }
                            }
                            this.tokenUpdating = null;
                            return throwError(err);
                        }),
                        map(
                            (ev: HttpEvent<any>) => {
                                if (ev.type === HttpEventType.Response) {
                                    this.authData.refreshTokenExpiration = -1;
                                    this.authData.accessTokenExpiration = Date.now() + (Number(ev.body.expires_in) * 1000);
                                    this.authData.accessToken = ev.body.access_token;
                                    if (ev.body.refreshToken != null) {
                                        this.authData.refreshToken = ev.body.refresh_token;
                                    }

                                    this.headers$.next({
                                        authorization: 'BEARER ' + this.authData.accessToken
                                    });
                                    localStorage.setItem('auth', JSON.stringify(this.authData));
                                    this.tokenUpdating = null;
                                    return this.headers$.value;
                                }
                                return ev;
                            }
                        ),
                        filter(ev => ev.type !== 0)
                    );
                    return this.tokenUpdating;
                } else {
                    return this.headers$.pipe(skip(1));
                }
            }
        }
        return this.headers$;
    }

    private isAccessTokenExpired(): boolean {
        if (this.authData.accessTokenExpiration < 0) {
            return false;
        }
        return (new Date().getTime() + 1000 > this.authData.accessTokenExpiration);
    }

    private isRefreshTokenExpired(): boolean {
        if (this.authData.refreshTokenExpiration < 0) {
            return false;
        }
        return (new Date().getTime() + 10000 > this.authData.refreshTokenExpiration);
    }
}
