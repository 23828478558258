import {UserModel} from '../../model/user.model';
import {Crud} from '../crud';
import {combineLatest, Observable} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config';

@Injectable()
export class UsersCrudService implements Crud<UserModel> {

    private changes: EventEmitter<void> = new EventEmitter();

    constructor(private http: HttpClient) {
    }

    count(filter?: string): Observable<number> {
        if (filter != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.getList().pipe(map(x => x.length));
    }

    create(object: UserModel): Observable<UserModel> {
        return this.http.post(AppConfig.settings.apiUrl + '/users', object).pipe(
            map((x: any) => {
                this.changes.next();
                return x.content;
            })
        );
    }

    delete(objects: UserModel[]): Observable<void> {
        const observables: Observable<void>[] = [];
        objects.forEach((object) => {
            observables.push(this.http.delete(AppConfig.settings.apiUrl + '/users/by-login/' + object.login).pipe(map(() => {
            })));
        });

        return combineLatest(observables).pipe(map(() => {
            this.changes.next();
            return;
        }));
        // return of(null).pipe(delay(5000));
    }

    getList(pageNo?: number, pageSize?: number, filter?: string, sortColum?: string, sortASC?: boolean): Observable<UserModel[]> {
        if (pageNo != null || pageSize != null || filter != null || sortColum != null || sortASC != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.http.get(AppConfig.settings.apiUrl + '/users?size=99999').pipe(
            map((x: any) => x.content)
        );
    }


    update(object: UserModel): Observable<UserModel> {
        if (object.password != null) {
            delete object.version;
            const pswd = {password: object.password};
            return this.http.put(AppConfig.settings.apiUrl + '/users/by-login/' + object.login, object).pipe(
                take(1),
                switchMap(() =>
                    this.http.put(AppConfig.settings.apiUrl + '/users/by-login/' + object.login + '/password', pswd)
                        .pipe(take(1),
                            map((x: any) => {
                                this.changes.next();
                                return object;
                            })
                        )
                )
            );

        } else {
            return this.http.put(AppConfig.settings.apiUrl + '/users/by-login/' + object.login, object).pipe(
                map((x: any) => {
                    this.changes.next();
                    return x.content;
                })
            );
        }

    }

    onChanges(): Observable<void> {
        return this.changes;
    }


}
