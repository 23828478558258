import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {HttpProgressInterceptor} from './interceptors/http-progress.interceptor';
import {GlobalErrorHandler} from './handlers/global-error.handler';
import {NotificationModule} from './services/notification/notification.module';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {OfflineIndicationModule} from './services/offline-indication/offline-indication.module';
import {OfflineIndicationInterceptor} from './interceptors/offline-indication.interceptor';
import {AppConfig} from './app.config';
import {AuthService} from './services/auth/auth.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NotificationModule,
        OfflineIndicationModule,
        // Vex
        VexModule,
        CustomLayoutModule
    ],
    providers: [
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true
        }, {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            deps: [AuthService], multi: true,
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpProgressInterceptor,
            multi: true,
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineIndicationInterceptor,
            multi: true,
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        }, {
            provide: MESSAGE_FORMAT_CONFIG, useValue: {multi: true, locales: ['en', 'ru']}
        },
        AuthService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
